import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { createUseStyles } from 'react-jss'
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image";

const useStyles = createUseStyles({
    layout: {
        'padding': '1rem',
        'min-height': '100vh',
        'display': 'grid',
        justifyContent: 'space-between',
        'grid-template-columns': 'repeat(3, minmax(300px, 3fr))',
        gridGap: '50px'
    },
    '@media (max-width: 1000px)': {
        layout: {
            'grid-template-columns': 'repeat(2, minmax(300px, 2fr))',
        }
    },
    '@media (max-width: 600px)': {
        layout: {
        'grid-template-columns': '1fr'
        }
    },
    project: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        textDecoration: 'none',
        height: '45vh',
        position: 'relative',
        color: 'white'
    },
    projectName: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        padding: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white'
    },
    description: {
        padding: '5px'
    },
    img: {
        height: '80%',
        backgroundColor: 'transparent'
    }
})

export default function Projects({ data }) {
    const classes = useStyles()
    return (
        <Layout style={classes.layout}>
            <SEO title="Projects" />
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <Link className={classes.project} key={node.id} to={node.fields.slug}>
                    <GatsbyImage
                        image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                        className={classes.img}
                        imgStyle={{objectPosition: "left"}} />
                    <div className={classes.projectName}>
                        {node.frontmatter.title}
                    </div>
                    <div className={classes.description}>
                        {node.frontmatter.description}
                    </div>
                </Link>
            ))}
        </Layout>
    );
}

export const query = graphql`{
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {category: {in: "Project"}}}
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "DD MMMM, YYYY")
          description
          tags
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
}
`
